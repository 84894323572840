import React from "react";


const Loading = ({ height }) => {
    return (
        // <div className="loading-box">
            <div className="Loading-dumbbell">
                <img src={require('./../../assets/images/weight.png')} alt="" />
                {/* <p>باشگاه بدنسازی جوانان</p> */}
            </div>
        // </div>
    )
}
export default Loading